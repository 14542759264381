




















































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, Product, Rate, Structure } from '@/interfaces'
import { mapState } from 'vuex'

interface Filter {
  text: string | null
  value: string | null
}

export default Vue.extend({
  components: {
    Nav,
  },
  data() {
    return {
      products: [],
      vatRates: [] as Rate[],
      structureVatRates: [[] as Rate[]],
      search: '',
      busy: false,
      structureFilter: [] as Filter[],
      types: [
        { text: 'Frais', value: 'frais' },
        { text: 'Emoluments', value: 'emolument' },
        { text: '% Acquéreur', value: 'percent_buyer' },
        { text: 'Fixe Acquéreur', value: 'fixe_buyer' },
        { text: '% Vendeur', value: 'percent_seller' },
        { text: 'Fixe Vendeur', value: 'fixe_seller' },
      ],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    products(newVal) {
      const structures = [] as Filter[]
      newVal.forEach((product: Product) => {
        if (product.structure) {
          structures.push({
            value: product.structure?.id?.toString() ?? null,
            text: product.structure?.name ?? null,
          })
          if (product.structureId && !this.structureVatRates[product.structureId]) {
            this.structureVatRates[product.structureId] = product.structure?.taxRate?.rates ?? []
          }
        }
      })

      // Filtering duplicates
      this.structureFilter = structures.reduce((unique: Filter[], o: Filter) => {
        if (!unique.some((obj: Filter) => obj.text === o.text && obj.value === o.value)) {
          unique.push(o)
        }
        return unique
      }, [])
    },
  },
  mounted() {
    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    this.getProducts()
  },
  methods: {
    getProducts() {
      if (!this.busy) {
        this.busy = true

        // Setting query URL
        this.$router
          .push({ query: Object.assign({}, this.$route.query, this.search ? { search: this.search } : null) })
          .catch(() => {
            // This empty catch method is here to avoid the "Avoided redundant navigation" error
          })

        const loading = this.$loading({
          target: '#productsTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/products', {
            params: {
              search: this.search,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.products = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    editProduct(product: Product) {
      if (product.id) {
        this.$router.push({
          name: 'product_edit',
          params: {
            id: product.id.toString(),
          },
        })
      }
    },
    deleteProduct(product: Product) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce produit ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/product/${product.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.getProducts()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    handleFilter(value: string, row: Record<string, string | number>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    handleStructureFilter(value: string, row: Record<string, string | number>, column: Record<string, string>) {
      const property = column['property']
      const structure = row[property] as Structure

      if (structure) {
        return structure.id?.toString() === value
      }
    },
    goToEditProduct(row: Product, column: Record<string, string>, cell: HTMLTableCellElement) {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'product_edit',
          params: {
            id: row.id?.toString() ?? '',
          },
        })
      }
    },
    getStructureName(structure: null | Structure) {
      if (structure == null) {
        return '-'
      } else {
        return structure.name
      }
    },
    getProductTaxRate(value: number, structure: null | Structure): string {
      if (structure !== null && structure.id) {
        return this.structureVatRates[structure.id]
          ? this.structureVatRates[structure.id].find((rate) => rate.value === value)?.label ?? '-'
          : '-'
      } else {
        return '-'
      }
    },
    getProductType(value: string): string {
      return this.types.find((type) => type.value === value)?.text ?? '-'
    },
    getProductActive(value: number): string {
      return value ? 'Oui' : 'Non'
    },
  },
})
